/*spacing.css*/
/* spacing helpers
p,m = padding,margin
a,t,r,b,l,h,v = all,top,right,bottom,left,horizontal,vertical
s,m,l,n = small(5px),medium(10px),large(20px),none(0px)
*/
.ptn,.pvn,.pan{padding-top:0 !important}
.pts,.pvs,.pas{padding-top:.5rem !important}
.ptm,.pvm,.pam{padding-top:1rem !important}
.ptl,.pvl,.pal{padding-top:1.5rem !important}
.prn,.phn,.pan{padding-right:0px !important}
.prs,.phs,.pas{padding-right:.5rem !important}
.prm,.phm,.pam{padding-right:1rem !important}
.prl,.phl,.pal{padding-right:1.5rem !important}
.pbn,.pvn,.pan{padding-bottom:0px !important}
.pbs,.pvs,.pas{padding-bottom:.5rem !important}
.pbm,.pvm,.pam{padding-bottom:1rem !important}
.pbl,.pvl,.pal{padding-bottom:1.5rem !important}
.pln,.phn,.pan{padding-left:0px !important}
.pls,.phs,.pas{padding-left:.5rem !important}
.plm,.phm,.pam{padding-left:1rem !important}
.pll,.phl,.pal{padding-left:1.5rem !important}
.mtn,.mvn,.man{margin-top:0px !important}
.mts,.mvs,.mas{margin-top:.5rem !important}
.mtm,.mvm,.mam{margin-top:1rem !important}
.mtl,.mvl,.mal{margin-top:1.5rem !important}
.mrn,.mhn,.man{margin-right:0px !important}
.mrs,.mhs,.mas{margin-right:.5rem !important}
.mrm,.mhm,.mam{margin-right:1rem !important}
.mrl,.mhl,.mal{margin-right:1.5rem !important}
.mbn,.mvn,.man{margin-bottom:0px !important}
.mbs,.mvs,.mas{margin-bottom:.5rem !important}
.mbm,.mvm,.mam{margin-bottom:1rem !important}
.mbl,.mvl,.mal{margin-bottom:1.5rem !important}
.mln,.mhn,.man{margin-left:0px !important}
.mls,.mhs,.mas{margin-left:.5rem !important}
.mlm,.mhm,.mam{margin-left:1rem !important}
.mll,.mhl,.mal{margin-left:1.5rem !important}
.mra,.mha{margin-right:auto !important}
.mla,.mha{margin-left:auto !important}

/*
.ptn,.pvn,.pan{padding-top:0px !important}
.pts,.pvs,.pas{padding-top:5px !important}
.ptm,.pvm,.pam{padding-top:10px !important}
.ptl,.pvl,.pal{padding-top:15px !important}
.prn,.phn,.pan{padding-right:0px !important}
.prs,.phs,.pas{padding-right:5px !important}
.prm,.phm,.pam{padding-right:10px !important}
.prl,.phl,.pal{padding-right:15px !important}
.pbn,.pvn,.pan{padding-bottom:0px !important}
.pbs,.pvs,.pas{padding-bottom:5px !important}
.pbm,.pvm,.pam{padding-bottom:10px !important}
.pbl,.pvl,.pal{padding-bottom:15px !important}
.pln,.phn,.pan{padding-left:0px !important}
.pls,.phs,.pas{padding-left:5px !important}
.plm,.phm,.pam{padding-left:10px !important}
.pll,.phl,.pal{padding-left:15px !important}
.mtn,.mvn,.man{margin-top:0px !important}
.mts,.mvs,.mas{margin-top:5px !important}
.mtm,.mvm,.mam{margin-top:10px !important}
.mtl,.mvl,.mal{margin-top:15px !important}
.mrn,.mhn,.man{margin-right:0px !important}
.mrs,.mhs,.mas{margin-right:5px !important}
.mrm,.mhm,.mam{margin-right:10px !important}
.mrl,.mhl,.mal{margin-right:15px !important}
.mbn,.mvn,.man{margin-bottom:0px !important}
.mbs,.mvs,.mas{margin-bottom:5px !important}
.mbm,.mvm,.mam{margin-bottom:10px !important}
.mbl,.mvl,.mal{margin-bottom:15px !important}
.mln,.mhn,.man{margin-left:0px !important}
.mls,.mhs,.mas{margin-left:5px !important}
.mlm,.mhm,.mam{margin-left:10px !important}
.mll,.mhl,.mal{margin-left:15px !important}
.mra,.mha{margin-right:auto !important}
.mla,.mha{margin-left:auto !important}
*/

.txl{text-align:left !important}
.txc{text-align:center !important}
.txr{text-align:right !important}