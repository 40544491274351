$wbsc-primary: #1B3350;
$color-info: #dce7f3;

$members-primary: $flat-green-haze;
.bg-mp20 { background: rgba($members-primary, .2) !important; }
$members-secondary: #017098;
.bg-ms20 { background: rgba($members-secondary, .2) !important; }
.text-ms { color: $members-secondary !important; }
$members-tertiary: $flat-fire-bush;

$admin-primary: $flat-burnt-orange;
$pool-primary: $flat-rebecca-purple;

$pricing-family: $flat-san-marino;
$pricing-couples: $flat-wisteria;
$pricing-single: $flat-zest;



// Backgrounds

.bg-delinquent { background: #ffcccc; }

// Text Colors

.text-delinquent { color: #ff0000; }