@media screen and (max-width: $screen-md) {

  .header-main {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.5rem;
  }

  .content-main {
    margin-top: 3rem;
  }

}