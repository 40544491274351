section.settings {
  display: flex;
  .setting {
    flex: 1 1 0px;
  }
  .key {
  }
  .value {
  }
}

.dashanchor {
  &:hover {
    text-decoration: none;
  }
}

.dashbutton {
  border-radius: 1rem;
  margin-bottom: 2rem;
  padding: .5rem;
  .data {
    min-height: 7rem;
    text-align: center;
    font-size: 3.5rem;
    padding-top: 1rem;
  }
  .icon {
    min-height: 7rem;
    text-align: center;
    .fa {
      font-size: 5rem;
      padding-top: 1rem;
    }
    .material-icons {
      font-size: 5rem;
      padding-top: 1rem;
    }
  }
  .title {
    font-size: 1.5rem;
    text-align: center;
  }

  &:hover {
    background: #f6f6f6;
  }

  @media screen and (max-width: $screen-xs) {
    .title {
      font-size: 1.2rem;
    }

  }

}

.infobar {
  padding: 1rem;
  margin: 2rem 0;
  background: #f6f6f6;
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: $members-primary;
  }
  .setting {
    font-size: 1.5rem;
    font-weight: bold;
    color: $members-secondary;
  }
}

.data-key {
  background: lighten($wbsc-primary,70%);
  padding: .5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: $wbsc-primary;
}

.data-value {
  background: lighten($wbsc-primary,75%);
  padding: .5rem 1rem;
  font-size: 1.5rem;
  color: $members-primary;
}

.family {

  .memberline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .name {
      font-size: 2.5rem;
      flex: 1 1 auto;
      padding: 0 3rem;
    }

    .photo {

      flex: 0 1 auto;

      .imgborder {
        width: 75px;
        height: 100px;
        border: 1px solid #ccc;
      }

      span.rollover {
        -o-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -webkit-transition: -webkit-transform 1s;
        background:url(/images/editphoto.png) center center no-repeat #000;
        cursor: pointer;
        height: 100px;
        width: 75px;
        position: absolute;
        z-index: 10;
        opacity: 0;
      }

      span.rollover:hover {
        opacity: .7;
        -o-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -webkit-transition: -webkit-transform 1s;
        -webkit-box-shadow: 0px 0px 4px #000;
        -moz-box-shadow: 0px 0px 4px #000;
        box-shadow: 0px 0px 4px #000;
      }

    }

    .buttons {
      flex: 0 1 auto;
    }

    .lineitem {
      border-radius: .5rem;
      min-width: 100%;
      margin-bottom: 1rem;
    }

    .linelabel {
      font-size: 1.75rem;
      color: #fff;
      background: $members-primary;
      padding: 1rem;
    }

    .datapoint {
      background: #eee;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: $members-primary;
        padding-right: 1rem;
      }
    }

    .actionbuttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }

}

.sl-table {
  border: 0;
  td {
    border: 0 !important;
  }
}
.sl-name {
  font-size: 2rem;
  font-weight: bold;
}

.sl-sessiondates {
  color: #aaa;
}

.sl-timeslot {
  padding: .5rem;
  margin: .5rem;
}

.sl-empty {
  background: #eee;
}
.sl-room {
  background: lighten(green,70%);
}
.sl-almost {
  background: lighten(yellow,40%);
}
.sl-full {
  background: lighten(red,40%);
}

.sl-signups {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  color: #999;
  .sl-waitlisted {
    padding-left: 2rem;
  }
}

.practice {
  background: #eee;
  margin: 1rem;
  padding: 1rem;
}