.dashboard {

  padding: 1rem 0;

  .dashboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    cursor: pointer;

    .dashbox {
      flex: 0 1 25%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      border: .5rem solid #fff;
      padding: 1rem;
      color: #fff;
      .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        .datum {
          font-size: 4rem;
        }
        .title {
          font-size: 1.5rem;
        }
      }
      .icon {
        font-size: 6rem;
        .material-icons {
          font-size: 6rem;
        }
      }
    }

    .dashbox-wholerow {
      flex: 0 1 100%;
    }
    .dashbox-halfrow {
      flex: 0 1 50%;
    }
    .dashbox-thirdrow {
      flex: 0 1 33.333333%;
    }

    .dashbox-active {
      background: $flat-eucalyptus;
    }
    .dashbox-active-sub {
      background: lighten($flat-eucalyptus, 50%);
      color: $flat-eucalyptus;
    }
    .dashbox-inactive {
      background: $flat-valencia;
    }
    .dashbox-inactive-sub {
      background: lighten($flat-valencia, 40%);
      color: $flat-valencia;
    }
    .dashbox-all {
      background: $flat-lightning-yellow;
    }
    .dashbox-visitors {
      background: $flat-san-marino;
    }
    .dashbox-concessions {
      background: $flat-edward;
    }
    .dashbox-events {
      background: $flat-rebecca-purple;
    }
    .dashbox-board {
      background: $flat-burnt-orange;
    }
    .dashbox-swimteam {
      background: $flat-jelly-bean;
    }
    .dashbox-turquoise {
      background: #1abc9c;
    }
    .dashbox-emerald {
      background: #2ecc71;
    }
    .dashbox-peter-river {
      background: #3498db;
    }
    .dashbox-amethyst {
      background: #9b59b6;
    }
    .dashbox-wet-asphalt {
      background: #34495e;
    }
    .dashbox-green-sea {
      background: #16a085;
    }
    .dashbox-nephritis {
      background: #27ae60;
    }
    .dashbox-belize-hole {
      background: #2980b9;
    }
    .dashbox-wisteria {
      background: #8e44ad;
    }
    .dashbox-midnight-blue {
      background: #2c3e50;
    }
    .dashbox-sun-flower {
      background: #f1c40f;
    }
    .dashbox-carrot {
      background: #e67e22;
    }
    .dashbox-alizarin {
      background: #e74c3c;
    }
    .dashbox-clouds {
      background: #ecf0f1;
    }
    .dashbox-concrete {
      background: #95a5a6;
    }
    .dashbox-orange {
      background: #f39c12;
    }
    .dashbox-pumpkin {
      background: #d35400;
    }
    .dashbox-pomegranate {
      background: #c0392b;
    }
    .dashbox-silver {
      background: #bdc3c7;
    }
    .dashbox-asbestos {
      background: #7f8c8d;
    }
    .dashbox-default {
      background: $flat-buttercup;
    }
  }

  .dashicon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .dashicon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-end;
      height: 10rem;
      width: 10rem;
      text-align: center;
      border-radius: 1rem;
      position: relative;
      margin: 0 1rem 1rem 0;
      .icon {
        font-size: 5rem;
        line-height: 1;
        .material-icons {
          font-size: 5rem;
        }
      }
      .data {
        padding: 1rem;
        .datum {
          font-size: 2rem;
          padding: .25rem .5rem;
          border-radius: .5rem;
        }
      }
      .title {
        font-size: 1.3rem;
      }
      .overlay {
        position: absolute;
        text-align: center;
        top: 40%;
        width: 100%;
      }
    }

    .dashicon-default {
      $basecolor: $flat-edward;
      color: darken($basecolor,20%);
      background: lighten($basecolor,25%);
      &:hover {
        background: lighten($basecolor,20%);
      }
      .datum {
        background: darken($basecolor,20%);
        color: lighten($basecolor,30%);
      }
    }

    .dashicon-active {
      $basecolor: $flat-gossip;
      color: darken($basecolor,20%);
      background: lighten($basecolor,25%);
      &:hover {
        background: lighten($basecolor,20%);
      }
      .datum {
        background: darken($basecolor,20%);
        color: lighten($basecolor,30%);
      }
    }

    .dashicon-inactive {
      $basecolor: $flat-new-york-pink;
      color: darken($basecolor,20%);
      background: lighten($basecolor,25%);
      &:hover {
        background: lighten($basecolor,20%);
      }
      .datum {
        background: darken($basecolor,20%);
        color: lighten($basecolor,30%);
      }
    }

  }

  .dashtable {
    .table-title {
      font-size: 2rem;
    }
    .title {
      font-weight: bold;
    }
    .data {
      text-align: right;
    }
    .buttons {
      text-align: right;
    }
  }
}

@media screen and (max-width: $screen-md) {

  .dashbox {
    justify-content: center;
    align-content: center;
    .info {
      flex: 1 1 auto;
      justify-content: center;
      align-content: center;
      text-align: center;
    }
    .icon {
      display: none;
    }
  }

}