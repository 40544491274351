.wbscalert {
  background: red;
  color: #fff;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    font-size: 5rem;
    padding: 1.5rem 4rem;
  }
}

.wbscalert-info-circle {
  background: #fec;
  color: darken(#fec,50%);
}