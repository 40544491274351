.content-main {
  margin: 5rem auto 0;
}

.name-link {
  color: #fff;
  &:hover {
    color: #ccc;
    text-decoration: none;
  }
}

.row.no-pad {
  margin-right:0;
  margin-left:0;
}
.row.no-pad > [class*='col-'] {
  padding-right:0;
  padding-left:0;
}

.page-banner {
  background: lighten($wbsc-primary,30%);
  color: lighten($wbsc-primary,70%);
  font-size: 1.75rem;
  padding: 1rem 2rem;
  border-bottom: 1rem solid lighten($wbsc-primary, 60%);
}

.hnav {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    a {
      padding: 0 .75rem;
      display: block;
      &:hover {
        color: lighten($wbsc-primary,50%);
      }
    }
  }
}

.nav-left {
  ul {
    list-style: none;
    padding: 2rem 0 0 0;
    margin: 0;
    li {
      background: lighten($wbsc-primary,60%);
      margin-bottom: 1rem;
      a {
        display: block;
        padding: .5rem 0 .5rem 2rem;
        color: $wbsc-primary;
        font-size: 1.5rem;
        &.active {
          background: lighten($wbsc-primary,30%);
          color: #fff;
        }
        &:hover {
          text-decoration: none;
          background: lighten($wbsc-primary,40%);
          color: #fff;
        }
      }
    }
  }
}

footer.mainfooter {
  padding: 1rem 2rem;
  background: lighten($wbsc-primary,65%);
  color: lighten($wbsc-primary, 30%);
}

// Form Errors

.form-errors {
  background: #c00;
  color: #fff;
  padding: 1rem;
  margin: 1rem 0;
}

// Admin Specific

.btn-admin {
  @include button-variant(#fff, $admin-primary, $admin-primary);
}

.panel-admin {
  @include panel-variant($admin-primary, #fff, $admin-primary, $admin-primary);
}

// Pool Specific

.btn-pool {
  @include button-variant(#fff, $pool-primary, $pool-primary);
}

.panel-pool {
  @include panel-variant($pool-primary, #fff, $pool-primary, $pool-primary);
}

// Callouts

.callout {
  background: $wbsc-primary;
  padding: 1rem;
  font-size: 1.75rem;
}

.callout-right {
  float: right;
  margin: 1rem 0 1rem 1rem;
}

.callout-info {
  background: $color-info;
  color: $wbsc-primary;
}

// Headshots
.headshot-gallery {
  display: flex;

}
.headshot {
  border: 1px solid #ccc;
}
.headshot-full {
  width: 150px;
  height: 200px;
}
.headshot-default {
  width: 75px;
  height: 100px;
}
.headshot-sm {
  width: 30px;
  height: 40px;
}
.headshot-xs {
  width: 21px;
  height: 28px;
}

// Media Queries

@media screen and (max-width: $screen-xs) {

  .center-when-sm {
    margin: auto;
    text-align: center;
  }

}