$flat-chestnut-rose          : #d24d57;
$flat-pomegranate            : #f22613;
$flat-thunderbird            : #d91e18;
$flat-old-brick              : #96281b;
$flat-flamingo               : #ef4836;
$flat-valencia               : #d64541;
$flat-tall-poppy             : #c0392b;
$flat-monza                  : #cf000f;
$flat-cinnabar               : #e74c3c;
$flat-razzmatazz             : #db0a5b;
$flat-sunset-orange          : #f64747;
$flat-wax-flower             : #f1a9a0;
$flat-cabaret                : #d2527f;
$flat-new-york-pink          : #e08283;
$flat-radical-red            : #f62459;
$flat-sunglo                 : #e26a6a;
$flat-snuff                  : #dcc6e0;
$flat-rebecca-purple         : #663399;
$flat-honey-flower           : #674172;
$flat-wistful                : #aea8d3;
$flat-plum                   : #913d88;
$flat-seance                 : #9a12b3;
$flat-medium-purple          : #bf55ec;
$flat-light-wisteria         : #be90d4;
$flat-studio                 : #8e44ad;
$flat-wisteria               : #9b59b6;
$flat-san-marino             : #446cb3;
$flat-alice-blue             : #e4f1fe;
$flat-royal-blue             : #4183d7;
$flat-picton-blue            : #59abe3;
$flat-spray                  : #81cfe0;
$flat-shakespeare            : #52b3d9;
$flat-humming-bird           : #c5eff7;
$flat-picton-blue            : #22a7f0;
$flat-curious-blue           : #3498db;
$flat-madison                : #2c3e50;
$flat-dodger-blue            : #19b5fe;
$flat-ming                   : #336e7b;
$flat-ebony-clay             : #22313f;
$flat-malibu                 : #6bb9f0;
$flat-curious-blue           : #1e8bc3;
$flat-chambray               : #3a539b;
$flat-pickled-bluewood       : #34495e;
$flat-hoki                   : #67809f;
$flat-jelly-bean             : #2574a9;
$flat-jacksons-purple        : #1f3a93;
$flat-jordy-blue             : #89c4f4;
$flat-steel-blue             : #4b77be;
$flat-fountain-blue          : #5c97bf;
$flat-medium-turquoise       : #4ecdc4;
$flat-aqua-island            : #a2ded0;
$flat-gossip                 : #87d37c;
$flat-dark-sea-green         : #90c695;
$flat-eucalyptus             : #26a65b;
$flat-caribbean-green        : #03c9a9;
$flat-silver-tree            : #68c3a3;
$flat-downy                  : #65c6bb;
$flat-mountain-meadow        : #1bbc9b;
$flat-light-sea-green        : #1ba39c;
$flat-medium-aquamarine      : #66cc99;
$flat-turquoise              : #36d7b7;
$flat-madang                 : #c8f7c5;
$flat-riptide                : #86e2d5;
$flat-shamrock               : #2ecc71;
$flat-mountain-meadow        : #16a085;
$flat-emerald                : #3fc380;
$flat-green-haze             : #019875;
$flat-free-speech-aquamarine : #03a678;
$flat-ocean-green            : #4daf7c;
$flat-niagara                : #2abb9b;
$flat-jade                   : #00b16a;
$flat-salem                  : #1e824c;
$flat-observatory            : #049372;
$flat-jungle-green           : #26c281;
$flat-cape-honey             : #fde3a7;
$flat-california             : #f89406;
$flat-fire-bush              : #eb9532;
$flat-tahiti-gold            : #e87e04;
$flat-casablanca             : #f4b350;
$flat-crusta                 : #f2784b;
$flat-jaffa                  : #eb974e;
$flat-lightning-yellow       : #f5ab35;
$flat-burnt-orange           : #d35400;
$flat-buttercup              : #f39c12;
$flat-ecstasy                : #f9690e;
$flat-sandstorm              : #f9bf3b;
$flat-jaffa                  : #f27935;
$flat-zest                   : #e67e22;
$flat-white-smoke            : #ececec;
$flat-lynch                  : #6c7a89;
$flat-pumice                 : #d2d7d3;
$flat-gallery                : #eeeeee;
$flat-silver-sand            : #bdc3c7;
$flat-porcelain              : #ecf0f1;
$flat-cascade                : #95a5a6;
$flat-iron                   : #dadfe1;
$flat-edward                 : #abb7b7;
$flat-cararra                : #f2f1ef;
$flat-silver                 : #bfbfbf;

// flatuicolors.com
$flat-turquoise              : #1abc9c;
$flat-emerald                : #2ecc71;
$flat-peter-river            : #3498db;
$flat-amethyst               : #9b59b6;
$flat-wet-asphalt            : #34495e;
$flat-green-sea              : #16a085;
$flat-nephritis              : #27ae60;
$flat-belize-hole            : #2980b9;
$flat-wisteria               : #8e44ad;
$flat-midnight-blue          : #2c3e50;
$flat-sun-flower             : #f1c40f;
$flat-carrot                 : #e67e22;
$flat-alizarin               : #e74c3c;
$flat-clouds                 : #ecf0f1;
$flat-concrete               : #95a5a6;
$flat-orange                 : #f39c12;
$flat-pumpkin                : #d35400;
$flat-pomegranate            : #c0392b;
$flat-silver                 : #bdc3c7;
$flat-asbestos               : #7f8c8d;
