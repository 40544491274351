.btn-subdued {
  @include button-variant($members-primary,rgba($members-primary,0.2),rgba($members-primary,0.2));
  &:hover {
    background: rgba($members-primary,0.3);
  }
}

.btn-pricing-family {
  @include button-variant(#fff, $pricing-family, $pricing-family);
  &:hover {
    background: darken($pricing-family, 10%);
  }
}

.btn-pricing-couples {
  @include button-variant(#fff, $pricing-couples, $pricing-couples);
  &:hover {
    background: darken($pricing-couples, 10%);
  }
}

.btn-pricing-single {
  @include button-variant(#fff, $pricing-single, $pricing-single);
  &:hover {
    background: darken($pricing-single, 10%);
  }
}

.btn-pricing-synergy {
  @include button-variant(#fff, #236aba, #236aba);
  &:hover {
    background: darken(#236aba, 10%);
  }
}

.btn-white-outline {
  @include button-variant(#ccc,transparent,#ccc);
  color: #ccc !important;
  &:hover {
    background: rgba(255,255,255,.1);
    color: #fff !important;
    border-color: #fff !important;
  }
}

.btn-pool {
  @include button-variant(#fff, #663399, #663399);
  &:hover {
    background: darken(#663399, 10%);
    color: #fff !important;
  }
}

.btn-clouds {
  @include button-variant(#999, $flat-clouds, $flat-clouds);
  &:hover {
    background: darken($flat-clouds, 10%);
    color: #666 !important;
  }
}

.btn-concrete {
  @include button-variant(#fff, #663399, #663399);
  &:hover {
    background: darken(#663399, 10%);
    color: #fff !important;
  }
}

.btn-silver {
  @include button-variant(#fff, #663399, #663399);
  &:hover {
    background: darken(#663399, 10%);
    color: #fff !important;
  }
}

.btn-asbestos {
  @include button-variant(#fff, $flat-asbestos, $flat-asbestos);
  &:hover {
    background: darken($flat-asbestos, 10%);
    color: #fff !important;
  }
}

.btn-black {
  @include button-variant(#fff, #222, #111);
  &:hover {
    background: #111;
    color: #fff !important;
  }
}

