#nav-slider {
  background: #222;
  width: 220px;
  visibility: hidden;
  z-index: 1000;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    display: block;
    padding: 1rem 1rem 1rem 2rem;
    color: #ccc;
    &:hover {
      background: #333;
      color: #fff;
      text-decoration: none;
    }
  }
}

.wbscnavbar {
  color: #fff;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.5;

  a {
    color: #eee;
    &:hover {
      color:#fff;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .logout {
    font-size: 1rem;
    border-left: 1px solid #ccc;
    padding: .2rem 0 .2rem 1rem;
    vertical-align: 10%;
  }

  @media screen and (min-width: $screen-md) {

    .title {
      font-size: 1.5rem;
    }

    .logout2 {
      font-size: 1.1rem;
      border-left: 1px solid #ccc;
      padding: .2rem 1rem;
      vertical-align: 10%;
    }

  }

}

.wbscnavbar-main {
  background: $wbsc-primary;
}

.wbscnavbar-members {
  background: $members-primary;
}

.wbscnavbar-admin {
  background: $admin-primary;
}

.wbscnavbar-pool {
  background: $flat-rebecca-purple;
}