.pricing-memberships {
  border: 1px solid $members-primary;
  text-align: center;
  .name {
    background: $members-primary;
    color: #fff;
    padding: 1rem;
    font-size: 2rem;
  }
  .box {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
  }
  .button {
    padding: 1rem;
  }
  .subtitle {
    color: $members-primary;
  }
  .money {
    font-size: 2rem;
  }
}

.pricing-family {
  border: 1px solid $pricing-family;
  .name { background: $pricing-family; }
  .subtitle { color: $pricing-family; }
}

.pricing-couples {
  border: 1px solid $pricing-couples;
  .name { background: $pricing-couples; }
  .subtitle { color: $pricing-couples; }
}

.pricing-single {
  border: 1px solid $pricing-single;
  .name { background: $pricing-single; }
  .subtitle { color: $pricing-single; }
}

.pricing-blurb {
  font-size: 1.75rem;
  line-height: 1.5;
  margin: 4rem 0;
  text-align: center;
}

.cart-items {
  margin: 2rem 0;
  .cart-item {
    margin-top: 1rem;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    .column-label {
      font-weight: bold;
      border-bottom: 1px solid #ccc;
    }
    .item-title {
      flex: 6 6 0px;
    }
    .item-qty {
      flex: 1 1 0px;
      text-align: center;
    }
    .item-price {
      flex: 3 3 0px;
      text-align: right;
    }
    .item-remove {
      flex: 1 1 0px;
      text-align: right;
    }
  }
  .faux-btn {
    background: #eee;
    padding: .1rem .5rem;
    border-radius: .5rem;
    font-size: 1.65rem;
    font-weight: bold;
    color: #990000;
    &:hover {
      text-decoration: none;
      cursor: pointer;
      background: #bbb;
    }
  }
}

.moreitemswrapper {
  display2: flex;
  .item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .item-title {
      flex: 6 6 0px;
    }
    .item-price {
      flex: 2 2 0px;
      text-align: right;
    }
    .item-qty {
      flex: 2 2 0px;
    }
    .item-add {
      flex: 2 2 0px;
      text-align: right;
    }
  }
}

.cc-form {
  .cardimages {
    text-align: center;
    margin: 1rem 0 2rem;
    color: #666;
  }
  .payment-amount {
    font-size: 2rem;
  }
}