body.homepage {

  background: lighten($wbsc-primary,70%);

  .homeheader {

    color: #fff;
    display: flex;
    justify-content: space-around;
    //align-items: stretch;
    flex-wrap: wrap;

    background-image: linear-gradient(rgba(27, 51, 80, 0.9), rgba(27, 51, 80, 0.9)), url('/images/pool.jpg');
    background-position: center center;
    background-size: cover;

    div.hhbox {
      display: flex;
      align-items: center;
      min-width: 320px;
      flex-basis: 10%;
      flex-grow: 1;
    }

    .logo {
      border: 2rem solid transparent;
      img {
        margin: 0 auto;
        width: 100%;
      }
    }

    .blurb {
      color: lighten($wbsc-primary,70%);
      border: 3rem solid transparent;
      font-size: 2rem;
      text-align: center;
    }

    .poolhours {
      border: 1rem solid transparent;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      .hhline {
        flex: 1 0 100%;
        max-width: 220px;
      }
      .title {
        font-size: 2.5rem;
        text-align: center;
        color: #fff;
      }
      .timebox {
        display: flex;
        justify-content: space-around;
        font-size: 2.2rem;
      }
      .hhlabel {
        color: lighten($wbsc-primary,50%);
      }
      .timevalue {
        color: lighten($wbsc-primary,70%);
      }
      .datetime {
        margin: .5rem 0;
        color: lighten($wbsc-primary,70%);
        font-size: 2rem;
        text-align: center;
      }
      .countdown {
        color: lighten($wbsc-primary,50%);
        font-size: 2rem;
        text-align: center;
        display: flex;
        justify-content: center;
        p {
          margin: 0;
          padding: 0;
        }
        .timeref { margin: 0 .25rem;}
        .hours {
          margin-left: .5rem;
        }
      }
    }

  }

  .announcements {
    background: #fff;
    margin-top: 2rem;
    padding: 0 2rem;
    header {
      color: $wbsc-primary;
      font-size: 2rem;
      padding: 0 0 1rem;
      padding-top: 1rem;
      margin-bottom: 2rem;
      border-bottom: .25rem solid lighten($wbsc-primary,50%);
    }
    .announcement {
      margin: 2rem 0;
      padding-bottom: 2rem;
      border-bottom: 1px solid #eee;
      .title {
        font-weight: bold;
        font-size: 1.8rem;
        color: $wbsc-primary;
      }
      .body {
        margin-top: 1rem;
      }
    }
  }

  .forecast {
    .conditions {
      line-height: 1;
      display: flex;
      justify-content: center;
      .tempbox {
        border: 1rem solid transparent;
        .temp {
          font-size: 6rem;
          line-height: 1;
        }
      }
      .icon {
        border: 1rem solid transparent;
        text-align: center;
        .theicon {
          font-size: 3.5rem;
        }
        .current-summary {
          font-size: 1.5rem;
          margin-top: .75rem;
        }
      }
    }
    .summary {
      text-align: center;
      font-size: 2rem;
      color: lighten($wbsc-primary,50%);
    }
    .hilow {
      display: flex;
      justify-content: center;
      .high {
        border: 1rem solid transparent;
      }
      .hilowlabel {
        color: lighten($wbsc-primary,50%);
      }
    }
  }

  .sidebar-box {
    padding: 1rem 2rem;
    background: $wbsc-primary;
    color: #fff;
    margin-top: 2rem;
    .title {
      font-size: 2rem;
      text-align: center;
      padding: 0 0 1rem;
      margin-bottom: 2rem;
      border-bottom: .25rem solid lighten($wbsc-primary,50%);
    }
  }

  .events {
    .eventtitle {
      font-size: 1.75rem;
    }
    .eventtime {
      font-size: 1.4rem;
      color: lighten($wbsc-primary,50%);
      margin: 0 0 1rem 0;
    }
    .description {
      margin-bottom: 2rem;
    }
  }
}

.fourohfour {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .errorbox {
    background: lighten($wbsc-primary,75%);
    padding: 2rem;
    border-radius: 1rem;
    .errorcode {
      font-size: 10rem;
      color: lighten($wbsc-primary,60%);
      line-height: 1;
    }
    .errormsg {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.boardmembers {
  display: flex;
  flex-wrap: wrap;
  .boardmember {
    flex: 1 0 50%;
    display: flex;

  }
  .photo {
    padding: 1rem;
  }
  .info {
    padding-left: 2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .title {
    font-size: 2rem;
  }
}