.header-main {
  background: $wbsc-primary;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  font-size: 3rem;
}

.header-members {
  background: $members-primary;
}

header.page {
  margin: 2rem 0 1rem;
  font-size: 2.5rem;
  color: $wbsc-primary;
}

header.section {
  text-align: center;
  background: rgba($members-primary, .2);
  color: $members-primary;
  padding: .5rem 0;
  font-size: 2rem;
  margin-bottom: 2rem;
}

// Titles

header.title-1 {
  font-size: 2rem;
  padding: .5rem 1rem;
  margin-bottom: 1rem;
  background: $wbsc-primary;
  color: #fff;
}

header.title-2 {
  font-size: 1.8rem;
  padding: .5rem 1rem;
  background: lighten($wbsc-primary,50%);
}

header.title-3 {
  font-size: 1.5rem;
  padding: .5rem 1rem;
  margin-bottom: 1rem;
  background: lighten($wbsc-primary,70%);
}
