.card {
  border: 1px solid #ccc;
  border-radius: .25rem;
  padding: 1rem;

}

.card-invoice {

  .hidden-rows {
    display: none;
  }

}
